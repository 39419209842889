/* purgecss start ignore */
@tailwind base;
@tailwind components;

/* Your own custom component styles */

/* purgecss end ignore */
@tailwind utilities;

body {
  margin: 0;
  font-family: Apple Color Emoji, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-sans {
  font-family: Apple Color Emoji, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
}

.text-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  
}
