.DayPicker {
  font-size: 1rem;
}
.DayPicker-wrapper {
  flex-direction: row;
  padding-bottom: 1em;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.DayPicker-Month {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  margin: 1em 1em 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.DayPicker-NavButton {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 50%;
  color: #8b9898;
  cursor: pointer;
  display: inline-block;
  height: 1.25em;
  left: auto;
  margin-top: 2px;
  position: absolute;
  right: 1.5em;
  top: 1em;
  width: 1.25em;
}
.DayPicker-NavButton:hover {
  opacity: 0.8;
}
.DayPicker-NavButton--prev {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
  margin-right: 1.5em;
}
.DayPicker-NavButton--next {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}
.DayPicker-NavButton--interactionDisabled {
  display: none;
}
.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: left;
}
.DayPicker-Caption > div {
  font-size: 1.15em;
  font-weight: 500;
}
.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}
.DayPicker-WeekdaysRow {
  display: table-row;
}
.DayPicker-Weekday {
  color: #8b9898;
  display: table-cell;
  font-size: 0.875em;
  padding: 0.5em;
  text-align: center;
}
.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}
.DayPicker-Body {
  display: table-row-group;
}
.DayPicker-Week {
  display: table-row;
}
.DayPicker-Day {
  border-radius: 50%;
  text-align: center;
}
.DayPicker-Day,
.DayPicker-WeekNumber {
  cursor: pointer;
  display: table-cell;
  padding: 0.5em;
  vertical-align: middle;
}
.DayPicker-WeekNumber {
  border-right: 1px solid #eaecec;
  color: #8b9898;
  font-size: 0.75em;
  min-width: 1em;
  text-align: right;
}
.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}
.DayPicker-Footer {
  padding-top: 0.5em;
}
.DayPicker-TodayButton {
  background-color: transparent;
  background-image: none;
  border: none;
  box-shadow: none;
  color: #4a90e2;
  cursor: pointer;
  font-size: 0.875em;
}
.DayPicker-Day--today {
  color: #d0021b;
  font-weight: 700;
}
.DayPicker-Day--outside {
  color: #8b9898;
  cursor: default;
}
.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
}
.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}
.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #4a90e2;
  color: #f0f8ff;
  position: relative;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51a0fa;
}
.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #f0f8ff;
}
.DayPickerInput {
  display: inline-block;
}
.DayPickerInput-OverlayWrapper {
  position: relative;
}
.DayPickerInput-Overlay {
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  left: 0;
  position: absolute;
  z-index: 1;
}
:root {
  --reach-dialog: 1;
  --reach-tabs: 1;
  --reach-tooltip: 1;
  --reach-listbox: 1;
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
[data-reach-dialog-overlay],
[data-react-tele-fade] {
  -webkit-animation: fade 0.15s ease;
  animation: fade 0.15s ease;
}
[data-reach-dialog-overlay] {
  background: rgba(0, 0, 0, 0.33);
  bottom: 0;
  left: 0;
  overflow: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  position: fixed;
  right: 0;
  top: 0;
}
@media (min-width: 640px) {
  [data-reach-dialog-overlay] {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
[data-reach-dialog-content] {
  outline: none;
  padding: 0;
}
[data-reach-listbox-option][data-current] {
  font-weight: 500;
}
[data-reach-listbox-option][aria-selected='true'] {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 245, 247, var(--tw-bg-opacity));
}
[data-reach-listbox-option][aria-selected='true'][aria-disabled] {
  background-color: transparent;
  cursor: default;
}
[data-tele-dialog-content],
[data-tele-modal-content] {
  margin: 10vh auto;
}
[data-tele-modal-content-noscroll] {
  margin: 2vh auto 0;
  max-height: 90vh;
}
.portal-ui-filter {
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
}
.portal-ui-table-filter {
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}
@media (min-width: 640px) {
  .portal-ui-desc-list {
    grid-template-columns: 100px 1fr;
  }
}
:root {
  --reach-menu-button: 1;
}
@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
[data-reach-menu-popover],
[data-reach-menu] {
  display: block;
  position: absolute;
}
[data-reach-menu-popover][hidden],
[data-reach-menu][hidden] {
  display: none;
}
[data-reach-menu-items],
[data-reach-menu-list] {
  -webkit-animation: slide-down 0.2s ease;
  animation: slide-down 0.2s ease;
  display: block;
  outline: none;
}
[data-reach-menu-item] {
  cursor: pointer;
  display: block;
  text-decoration: initial;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
[data-reach-menu-item][data-selected] {
  outline: none;
}
.portal-ui-scrollbar {
  scrollbar-color: #e5e7eb transparent;
  scrollbar-width: thin;
}
.portal-ui-scrollbar::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;
}
.portal-ui-scrollbar::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  border-radius: 0.375rem;
}
.portal-ui-scroll-overlay-y {
  overflow-y: overlay;
}
[data-react-tele-side-bar-nav] {
  scrollbar-color: #416586 transparent;
  scrollbar-width: thin;
}
[data-react-tele-side-bar-nav]::-webkit-scrollbar {
  width: 0.375rem;
}
[data-react-tele-side-bar-nav]::-webkit-scrollbar-thumb {
  background-color: #416586;
}
.portal-ui-table {
  caption-side: bottom;
}
.portal-ui-ellipsis:after {
  content: '';
  display: block;
}
[data-reach-menu-item][data-selected] {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(244, 245, 247, var(--tw-bg-opacity));
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.portal-ui-tab-menu [data-reach-menu-item][data-selected] {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  color: rgba(0, 158, 230, var(--tw-text-opacity));
}
.DayPicker-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 42rem;
}
.DayPicker-wrapper:focus {
  outline: none;
}
.DayPicker-NavButton--prev {
  left: 1.5em;
  right: auto;
}
.DayPicker-Month {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-top: 0;
}
.DayPicker-Weekday {
  --tw-text-opacity: 1;
  color: rgba(45, 51, 58, var(--tw-text-opacity));
  font-weight: 700;
}
.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: transparent;
}
.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover
  .DayPicker-DayInner {
  background-color: #f0f8ff;
}
.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover
  .DayPicker-DayInner {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(0, 158, 230, var(--tw-bg-opacity));
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.DayPicker-Day {
  --tw-text-opacity: 1;
  color: rgba(45, 51, 58, var(--tw-text-opacity));
  font-kerning: none;
  padding: 0.375rem;
}
.DayPicker-Day:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.DayPicker-Day:focus-visible {
  --tw-shadow: 0 0 0 3px hsla(0, 96%, 89%, 0.5);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: transparent;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)
  .DayPicker-DayInner {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 176, 255, var(--tw-bg-opacity));
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: transparent;
}
.DayPicker-Day--disabled {
  --tw-text-opacity: 1;
  color: rgba(236, 238, 241, var(--tw-text-opacity));
}
.DayPicker--range
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  padding-left: 0;
  padding-right: 0;
}
.DayPicker--range
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)
  .DayPicker-DayOuter {
  background-color: rgba(0, 176, 255, 0.1);
  width: 2.75rem;
}
.DayPicker--range
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)
  .DayPicker-DayOuter--lastDayOfMonth {
  border-bottom-right-radius: 9999px;
  border-top-right-radius: 9999px;
}
.DayPicker--range
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)
  .DayPicker-DayInner {
  --tw-text-opacity: 1;
  background-color: transparent;
  color: rgba(45, 51, 58, var(--tw-text-opacity));
}
.DayPicker--range
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):first-child
  .DayPicker-DayOuter {
  border-bottom-left-radius: 9999px;
  border-top-left-radius: 9999px;
}
.DayPicker--range
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):last-child
  .DayPicker-DayOuter {
  border-bottom-right-radius: 9999px;
  border-top-right-radius: 9999px;
}
.DayPicker--range
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--start
  .DayPicker-DayOuter {
  border-bottom-left-radius: 9999px;
  border-top-left-radius: 9999px;
  margin-left: auto;
  padding-left: 0.125rem;
  text-align: left;
  width: 2.5rem;
}
.DayPicker--range
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--start
  .DayPicker-DayInner {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(0, 176, 255, var(--tw-bg-opacity));
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.DayPicker--range
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--end
  .DayPicker-DayOuter {
  border-bottom-right-radius: 9999px;
  border-top-right-radius: 9999px;
  margin-right: auto;
  padding-right: 0.125rem;
  text-align: right;
  width: 2.5rem;
}
.DayPicker--range
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--end
  .DayPicker-DayInner {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(0, 176, 255, var(--tw-bg-opacity));
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.DayPicker--range
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--start.DayPicker-Day--end
  .DayPicker-DayOuter {
  --tw-shadow: 0 0 0 2px #66d0ff;
  background-color: transparent;
  border-bottom-right-radius: 9999px;
  border-top-right-radius: 9999px;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  width: 2rem;
}
.DayPicker--range
  .DayPicker-Day--outside
  + .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)
  .DayPicker-DayOuter {
  border-bottom-left-radius: 9999px;
  border-top-left-radius: 9999px;
}
.DayPicker-Dialog {
  width: 324px;
}
.form-checkbox,
.form-radio {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  background-origin: border-box;
  border-color: rgba(152, 166, 186, var(--tw-border-opacity));
  display: inline-block;
  flex-shrink: 0;
  vertical-align: middle;
}
.form-checkbox:focus,
.form-radio:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.form-checkbox:focus-visible,
.form-radio:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
    var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity));
  --tw-ring-opacity: 0.5;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.form-checkbox:checked,
.form-radio:checked {
  background-color: currentColor;
  background-position: 50%;
  background-size: 100% 100%;
  border-color: transparent;
}
.form-checkbox {
  border-radius: 0.25rem;
  border-width: 1px;
}
.form-checkbox:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3E%3C/svg%3E");
}
.form-radio {
  -webkit-print-color-adjust: exact;
  border-radius: 100%;
  border-width: 2px;
  height: 1rem;
  width: 1rem;
}
.form-radio:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 32 32' fill='none' stroke='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='10' stroke-width='3'/%3E%3C/svg%3E");
}
.form-checkbox[aria-checked='mixed'] {
  background-color: currentColor;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='%23fff' fill='none'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M18 12H6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: center;
  border-color: transparent;
}
.form-select {
  --tw-bg-opacity: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3E%3Cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3E%3C/svg%3E");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  border-width: 1px;
  padding-left: 0.75rem;
  padding-right: 2.5rem;
}
.form-select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.form-input,
.form-textarea {
  --tw-bg-opacity: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-width: 1px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.form-input:focus,
.form-textarea:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.form-input,
.form-select,
.form-textarea {
  --tw-border-opacity: 1;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-bottom: 0.5625rem;
  padding-top: 0.5625rem;
}
.form-input::-moz-placeholder,
.form-select::-moz-placeholder,
.form-textarea::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 132, 148, var(--tw-placeholder-opacity));
}
.form-input:-ms-input-placeholder,
.form-select:-ms-input-placeholder,
.form-textarea:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 132, 148, var(--tw-placeholder-opacity));
}
.form-input::placeholder,
.form-select::placeholder,
.form-textarea::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 132, 148, var(--tw-placeholder-opacity));
}
.form-input:focus,
.form-select:focus,
.form-textarea:focus {
  --tw-border-opacity: 1;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
    var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity));
  --tw-ring-opacity: 0.5;
  border-color: rgba(102, 208, 255, var(--tw-border-opacity));
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.form-textarea {
  line-height: 1.5rem;
}
.focus-visible-ring:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus-visible-ring:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
    var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity));
  --tw-ring-opacity: 0.4;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
*,
:after,
:before {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  --tw-shadow: 0 0 #0000;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(47, 72, 250, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-blur: var(--tw-empty, /*!*/ /*!*/);
  --tw-brightness: var(--tw-empty, /*!*/ /*!*/);
  --tw-contrast: var(--tw-empty, /*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty, /*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty, /*!*/ /*!*/);
  --tw-invert: var(--tw-empty, /*!*/ /*!*/);
  --tw-saturate: var(--tw-empty, /*!*/ /*!*/);
  --tw-sepia: var(--tw-empty, /*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty, /*!*/ /*!*/);
  --tw-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale)
    var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  --tw-backdrop-blur: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-brightness: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-contrast: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-grayscale: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-hue-rotate: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-invert: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-opacity: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-saturate: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-sepia: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate)
    var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.sr-only {
  clip: rect(0, 0, 0, 0);
  border-width: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.pointer-events-none {
  pointer-events: none;
}
.pointer-events-auto {
  pointer-events: auto;
}
.invisible {
  visibility: hidden;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.inset-0 {
  left: 0;
  right: 0;
}
.inset-0,
.inset-y-0 {
  bottom: 0;
  top: 0;
}
.inset-x-0 {
  left: 0;
  right: 0;
}
.-bottom-2 {
  bottom: -0.5rem;
}
.left-1\/2 {
  left: 50%;
}
.top-0 {
  top: 0;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
.top-4 {
  top: 1rem;
}
.top-1 {
  top: 0.25rem;
}
.top-2 {
  top: 0.5rem;
}
.top-10 {
  top: 2.5rem !important;
}
.top-11 {
  top: 2.75rem !important;
}
.top-1\/2 {
  top: 50%;
}
.right-3 {
  right: 0.75rem;
}
.-left-px {
  left: -1px;
}
.left-13 {
  left: 3.25rem;
}
.left-2 {
  left: 0.5rem;
}
.top-full {
  top: 100%;
}
.bottom-0 {
  bottom: 0;
}
.right-1 {
  right: 0.25rem;
}
.top-9 {
  top: 2.25rem;
}
.bottom-2 {
  bottom: 0.5rem;
}
.left-4 {
  left: 1rem;
}
.m-0 {
  margin: 0;
}
.-my-1 {
  margin-bottom: -0.25rem;
  margin-top: -0.25rem;
}
.my-1 {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}
.-mx-1\.5 {
  margin-left: -0.375rem;
  margin-right: -0.375rem;
}
.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.-my-1\.5 {
  margin-bottom: -0.375rem;
  margin-top: -0.375rem;
}
.-my-2\.5 {
  margin-bottom: -0.625rem;
  margin-top: -0.625rem;
}
.-my-2 {
  margin-bottom: -0.5rem;
  margin-top: -0.5rem;
}
.my-0\.5 {
  margin-bottom: 0.125rem;
  margin-top: 0.125rem;
}
.my-0 {
  margin-bottom: 0;
  margin-top: 0;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.-my-px {
  margin-bottom: -1px;
  margin-top: -1px;
}
.my-2 {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.ml-1\.5 {
  margin-left: 0.375rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.mr-1\.5 {
  margin-right: 0.375rem;
}
.-ml-1 {
  margin-left: -0.25rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.-mr-1 {
  margin-right: -0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.-mt-2 {
  margin-top: -0.5rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mr-5 {
  margin-right: 1.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.ml-auto {
  margin-left: auto;
}
.-ml-2 {
  margin-left: -0.5rem;
}
.mb-0 {
  margin-bottom: 0;
}
.mr-2 {
  margin-right: 0.5rem;
}
.-ml-px {
  margin-left: -1px;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.ml-17 {
  margin-left: 4.25rem;
}
.ml-6 {
  margin-left: 1.5rem;
}
.mt-2\.5 {
  margin-top: 0.625rem;
}
.mt-0\.5 {
  margin-top: 0.125rem;
}
.mt-0 {
  margin-top: 0;
}
.ml-4 {
  margin-left: 1rem;
}
.-ml-8 {
  margin-left: -2rem;
}
.-ml-16 {
  margin-left: -4rem;
}
.-ml-24 {
  margin-left: -6rem;
}
.-ml-32 {
  margin-left: -8rem;
}
.-ml-40 {
  margin-left: -10rem;
}
.-ml-48 {
  margin-left: -12rem;
}
.mt-4 {
  margin-top: 1rem;
}
.-mt-1\.5 {
  margin-top: -0.375rem;
}
.-mr-2 {
  margin-right: -0.5rem;
}
.-mt-1 {
  margin-top: -0.25rem;
}
.-ml-12 {
  margin-left: -3rem;
}
.ml-0\.5 {
  margin-left: 0.125rem;
}
.ml-0 {
  margin-left: 0;
}
.ml-5 {
  margin-left: 1.25rem;
}
.-mr-14 {
  margin-right: -3.5rem;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.table {
  display: table;
}
.table-caption {
  display: table-caption;
}
.table-cell {
  display: table-cell;
}
.table-row-group {
  display: table-row-group;
}
.table-row {
  display: table-row;
}
.grid {
  display: grid;
}
.hidden {
  display: none;
}
.h-2 {
  height: 0.5rem;
}
.h-3 {
  height: 0.75rem;
}
.h-10 {
  height: 2.5rem;
}
.h-4 {
  height: 1rem;
}
.h-6 {
  height: 1.5rem;
}
.h-5 {
  height: 1.25rem;
}
.h-2\.5 {
  height: 0.625rem;
}
.h-9 {
  height: 2.25rem;
}
.h-1 {
  height: 0.25rem;
}
.h-8 {
  height: 2rem;
}
.h-5\.5 {
  height: 1.375rem;
}
.h-12 {
  height: 3rem;
}
.h-48 {
  height: 12rem;
}
.h-44 {
  height: 11rem;
}
.h-30 {
  height: 7.5rem;
}
.h-screen {
  height: 100vh;
}
.h-full {
  height: 100%;
}
.max-h-screen-40 {
  max-height: 40vh;
}
.max-h-96 {
  max-height: 24rem;
}
.max-h-52 {
  max-height: 13rem;
}
.max-h-60 {
  max-height: 15rem;
}
.max-h-80 {
  max-height: 20rem;
}
.max-h-72 {
  max-height: 18rem;
}
.max-h-inherit {
  max-height: inherit;
}
.min-h-24 {
  min-height: 6rem;
}
.w-2 {
  width: 0.5rem;
}
.w-3 {
  width: 0.75rem;
}
.w-4\.5 {
  width: 1.125rem;
}
.w-4 {
  width: 1rem;
}
.w-6 {
  width: 1.5rem;
}
.w-5 {
  width: 1.25rem;
}
.w-2\.5 {
  width: 0.625rem;
}
.w-40 {
  width: 10rem;
}
.w-full {
  width: 100%;
}
.w-0 {
  width: 0;
}
.w-12 {
  width: 3rem;
}
.w-48 {
  width: 12rem;
}
.w-32 {
  width: 8rem;
}
.w-9 {
  width: 2.25rem;
}
.w-72 {
  width: 18rem;
}
.w-24 {
  width: 6rem;
}
.w-8 {
  width: 2rem;
}
.w-10 {
  width: 2.5rem;
}
.w-36 {
  width: 9rem;
}
.w-28 {
  width: 7rem;
}
.w-20 {
  width: 5rem;
}
.w-11 {
  width: 2.75rem;
}
.w-44 {
  width: 11rem;
}
.w-30 {
  width: 7.5rem;
}
.w-screen {
  width: 100vw;
}
.w-80 {
  width: 20rem;
}
.w-64 {
  width: 16rem;
}
.min-w-28 {
  min-width: 7rem;
}
.min-w-20 {
  min-width: 5rem;
}
.min-w-0 {
  min-width: 0;
}
.min-w-full {
  min-width: 100%;
}
.min-w-24 {
  min-width: 6rem;
}
.min-w-44 {
  min-width: 11rem;
}
.min-w-48 {
  min-width: 12rem;
}
.min-w-56 {
  min-width: 14rem;
}
.max-w-xs {
  max-width: 20rem;
}
.max-w-2xl {
  max-width: 42rem;
}
.max-w-6xl {
  max-width: 72rem;
}
.max-w-4xl {
  max-width: 56rem;
}
.max-w-full {
  max-width: 100%;
}
.max-w-7xl {
  max-width: 80rem;
}
.max-w-modal-medium {
  max-width: 51.25rem;
}
.max-w-xl {
  max-width: 36rem;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-2 {
  flex: 2 2 0%;
}
.flex-none {
  flex: none;
}
.flex-initial {
  flex: 0 1 auto;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-grow {
  flex-grow: 1;
}
.origin-left {
  transform-origin: left;
}
.origin-bottom {
  transform-origin: bottom;
}
.translate-y-0 {
  --tw-translate-y: 0px;
  transform: var(--tw-transform);
}
.translate-y-1 {
  --tw-translate-y: 0.25rem;
  transform: var(--tw-transform);
}
.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: var(--tw-transform);
}
.translate-y-2 {
  --tw-translate-y: 0.5rem;
  transform: var(--tw-transform);
}
.-translate-y-1 {
  --tw-translate-y: -0.25rem;
  transform: var(--tw-transform);
}
.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: var(--tw-transform);
}
.translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: var(--tw-transform);
}
.translate-x-0 {
  --tw-translate-x: 0px;
  transform: var(--tw-transform);
}
.-translate-x-2 {
  --tw-translate-x: -0.5rem;
  transform: var(--tw-transform);
}
.-translate-x-2\.5 {
  --tw-translate-x: -0.625rem;
  transform: var(--tw-transform);
}
.-translate-x-full {
  --tw-translate-x: -100%;
  transform: var(--tw-transform);
}
.rotate-180 {
  --tw-rotate: 180deg;
}
.rotate-0,
.rotate-180 {
  transform: var(--tw-transform);
}
.rotate-0 {
  --tw-rotate: 0deg;
}
.rotate-90 {
  --tw-rotate: 90deg;
  transform: var(--tw-transform);
}
.-rotate-90 {
  --tw-rotate: -90deg;
  transform: var(--tw-transform);
}
.scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: var(--tw-transform);
}
.scale-y-100 {
  --tw-scale-y: 1;
  transform: var(--tw-transform);
}
.scale-x-100 {
  --tw-scale-x: 1;
  transform: var(--tw-transform);
}
.scale-y-0 {
  --tw-scale-y: 0;
}
.scale-x-0,
.scale-y-0 {
  transform: var(--tw-transform);
}
.scale-x-0 {
  --tw-scale-x: 0;
}
.transform {
  transform: var(--tw-transform);
}
@-webkit-keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
.animate-spin {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
@-webkit-keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
.animate-pulse {
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-text {
  cursor: text;
}
.select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.resize-y {
  resize: vertical;
}
.list-disc {
  list-style-type: disc;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.content-start {
  align-content: flex-start;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-items-center {
  justify-items: center;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-5 {
  gap: 1.25rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-y-6 {
  row-gap: 1.5rem;
}
.gap-y-5 {
  row-gap: 1.25rem;
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
  margin-right: calc(1rem * var(--tw-space-x-reverse));
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  margin-top: calc(0.5rem * (1 - var(--tw-space-y-reverse)));
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
}
.space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-left: calc(0.625rem * (1 - var(--tw-space-x-reverse)));
  margin-right: calc(0.625rem * var(--tw-space-x-reverse));
}
.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-left: calc(0.75rem * (1 - var(--tw-space-x-reverse)));
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
}
.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  margin-top: calc(0.75rem * (1 - var(--tw-space-y-reverse)));
}
.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-left: calc(0.25rem * (1 - var(--tw-space-x-reverse)));
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overscroll-contain {
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.whitespace-pre-line {
  white-space: pre-line;
}
.break-words {
  overflow-wrap: break-word;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-none {
  border-radius: 0;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-l-md {
  border-bottom-left-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}
.rounded-r-none {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.rounded-r-md {
  border-bottom-right-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.rounded-l-none {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.rounded-t-xl {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.rounded-b-xl {
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border {
  border-width: 1px;
}
.border-2 {
  border-width: 2px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-t-8 {
  border-top-width: 8px;
}
.border-t {
  border-top-width: 1px;
}
.border-b-8 {
  border-bottom-width: 8px;
}
.border-l-4 {
  border-left-width: 4px;
}
.border-r {
  border-right-width: 1px;
}
.border-t-0 {
  border-top-width: 0;
}
.border-l {
  border-left-width: 1px;
}
.border-dashed {
  border-style: dashed;
}
.border-none {
  border-style: none;
}
.border-brand-500 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 176, 255, var(--tw-border-opacity));
}
.border-error {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity));
}
.border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.border-offwhite {
  --tw-border-opacity: 1;
  border-color: rgba(236, 238, 241, var(--tw-border-opacity));
}
.border-error-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 232, 231, var(--tw-border-opacity));
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 245, 247, var(--tw-border-opacity));
}
.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(210, 214, 220, var(--tw-border-opacity));
}
.border-darkgrey {
  --tw-border-opacity: 1;
  border-color: rgba(45, 51, 58, var(--tw-border-opacity));
}
.border-error-500 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity));
}
.border-warning-500 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 181, 59, var(--tw-border-opacity));
}
.border-success-500 {
  --tw-border-opacity: 1;
  border-color: rgba(46, 204, 113, var(--tw-border-opacity));
}
.border-info-500 {
  --tw-border-opacity: 1;
  border-color: rgba(69, 154, 254, var(--tw-border-opacity));
}
.border-lightergrey {
  --tw-border-opacity: 1;
  border-color: rgba(250, 250, 250, var(--tw-border-opacity));
}
.border-info-200 {
  --tw-border-opacity: 1;
  border-color: rgba(169, 208, 255, var(--tw-border-opacity));
}
.border-controlborder {
  --tw-border-opacity: 1;
  border-color: rgba(152, 166, 186, var(--tw-border-opacity));
}
.border-transparent {
  border-color: transparent;
}
.border-grey-700 {
  --tw-border-opacity: 1;
  border-color: rgba(208, 212, 217, var(--tw-border-opacity));
}
.border-carbon-100 {
  --tw-border-opacity: 1;
  border-color: rgba(184, 197, 210, var(--tw-border-opacity));
}
.border-carbon-500 {
  --tw-border-opacity: 1;
  border-color: rgba(18, 63, 104, var(--tw-border-opacity));
}
.border-carbon-200 {
  --tw-border-opacity: 1;
  border-color: rgba(65, 101, 134, var(--tw-border-opacity));
}
.border-opacity-50 {
  --tw-border-opacity: 0.5;
}
.border-opacity-20 {
  --tw-border-opacity: 0.2;
}
.bg-turquoise-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(188, 246, 230, var(--tw-bg-opacity));
}
.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(206, 185, 248, var(--tw-bg-opacity));
}
.bg-lemon-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 238, 179, var(--tw-bg-opacity));
}
.bg-success-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 246, 227, var(--tw-bg-opacity));
}
.bg-info-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 241, 255, var(--tw-bg-opacity));
}
.bg-warning-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 236, 204, var(--tw-bg-opacity));
}
.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 232, 232, var(--tw-bg-opacity));
}
.bg-offwhite {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 238, 241, var(--tw-bg-opacity));
}
.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 189, 253, var(--tw-bg-opacity));
}
.bg-brand-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 176, 255, var(--tw-bg-opacity));
}
.bg-carbon-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 50, 83, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(210, 214, 220, var(--tw-bg-opacity));
}
.bg-transparent {
  background-color: transparent;
}
.bg-error-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 67, 54, var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.bg-lemon-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 198, 0, var(--tw-bg-opacity));
}
.bg-brand-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(179, 231, 255, var(--tw-bg-opacity));
}
.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(222, 247, 236, var(--tw-bg-opacity));
}
.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 246, 178, var(--tw-bg-opacity));
}
.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(101, 119, 251, var(--tw-bg-opacity));
}
.bg-brand-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(102, 208, 255, var(--tw-bg-opacity));
}
.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 82, 82, var(--tw-bg-opacity));
}
.bg-mediumgrey {
  --tw-bg-opacity: 1;
  background-color: rgba(82, 91, 101, var(--tw-bg-opacity));
}
.bg-info-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 154, 254, var(--tw-bg-opacity));
}
.bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(78, 19, 195, var(--tw-bg-opacity));
}
.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 159, 110, var(--tw-bg-opacity));
}
.bg-turquoise-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(25, 179, 138, var(--tw-bg-opacity));
}
.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(194, 120, 3, var(--tw-bg-opacity));
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-grey-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 235, 238, var(--tw-bg-opacity));
}
.bg-teal-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 250, 250, var(--tw-bg-opacity));
}
.bg-darkgrey {
  --tw-bg-opacity: 1;
  background-color: rgba(45, 51, 58, var(--tw-bg-opacity));
}
.bg-error-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 232, 231, var(--tw-bg-opacity));
}
.bg-lightergrey {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 245, 247, var(--tw-bg-opacity));
}
.bg-carbon-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(65, 101, 134, var(--tw-bg-opacity));
}
.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 30, 46, var(--tw-bg-opacity));
}
.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(159, 166, 178, var(--tw-bg-opacity));
}
.bg-grey-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 243, 244, var(--tw-bg-opacity));
}
.bg-carbon-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(11, 38, 62, var(--tw-bg-opacity));
}
.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.bg-opacity-0 {
  --tw-bg-opacity: 0;
}
.bg-opacity-25 {
  --tw-bg-opacity: 0.25;
}
.bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}
.bg-opacity-5 {
  --tw-bg-opacity: 0.05;
}
.bg-opacity-60 {
  --tw-bg-opacity: 0.6;
}
.bg-opacity-75 {
  --tw-bg-opacity: 0.75;
}
.bg-opacity-90 {
  --tw-bg-opacity: 0.9;
}
.object-contain {
  -o-object-fit: contain;
  object-fit: contain;
}
.object-none {
  -o-object-fit: none;
  object-fit: none;
}
.p-4 {
  padding: 1rem;
}
.p-1\.5 {
  padding: 0.375rem;
}
.p-1 {
  padding: 0.25rem;
}
.p-0 {
  padding: 0;
}
.p-0\.5 {
  padding: 0.125rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-5 {
  padding: 1.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.py-0\.5 {
  padding-bottom: 0.125rem;
  padding-top: 0.125rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-0 {
  padding-bottom: 0;
  padding-top: 0;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-1 {
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.py-5 {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
}
.py-4 {
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.py-6 {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.py-3 {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}
.py-3\.5 {
  padding-bottom: 0.875rem;
  padding-top: 0.875rem;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.py-1\.5 {
  padding-bottom: 0.375rem;
  padding-top: 0.375rem;
}
.px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-0\.25 {
  padding-bottom: 0.0625rem;
  padding-top: 0.0625rem;
}
.py-7 {
  padding-bottom: 1.75rem;
  padding-top: 1.75rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.py-2\.5 {
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pl-11 {
  padding-left: 2.75rem;
}
.pr-2\.5 {
  padding-right: 0.625rem;
}
.pt-10 {
  padding-top: 2.5rem;
}
.pl-8 {
  padding-left: 2rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pl-5 {
  padding-left: 1.25rem;
}
.pt-0\.25 {
  padding-top: 0.0625rem;
}
.pt-0 {
  padding-top: 0;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pl-12 {
  padding-left: 3rem;
}
.pl-20 {
  padding-left: 5rem;
}
.pl-28 {
  padding-left: 7rem;
}
.pl-36 {
  padding-left: 9rem;
}
.pl-44 {
  padding-left: 11rem;
}
.pl-52 {
  padding-left: 13rem;
}
.pl-9 {
  padding-left: 2.25rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pr-10 {
  padding-right: 2.5rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pl-1\.75 {
  padding-left: 0.4375rem;
}
.pr-1\.5 {
  padding-right: 0.375rem;
}
.pl-1 {
  padding-left: 0.25rem;
}
.pr-1 {
  padding-right: 0.25rem;
}
.pl-1\.5 {
  padding-left: 0.375rem;
}
.pb-5 {
  padding-bottom: 1.25rem;
}
.pr-16 {
  padding-right: 4rem;
}
.pr-3 {
  padding-right: 0.75rem;
}
.pt-2\.5 {
  padding-top: 0.625rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pb-6 {
  padding-bottom: 1.5rem;
}
.pt-1 {
  padding-top: 0.25rem;
}
.pb-2\.25 {
  padding-bottom: 0.5625rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pr-8 {
  padding-right: 2rem;
}
.pt-5 {
  padding-top: 1.25rem;
}
.pt-7 {
  padding-top: 1.75rem;
}
.pl-7 {
  padding-left: 1.75rem;
}
.pl-16 {
  padding-left: 4rem;
}
.pl-24 {
  padding-left: 6rem;
}
.pl-32 {
  padding-left: 8rem;
}
.pl-40 {
  padding-left: 10rem;
}
.pl-48 {
  padding-left: 12rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pr-7 {
  padding-right: 1.75rem;
}
.pb-0\.5 {
  padding-bottom: 0.125rem;
}
.pb-0 {
  padding-bottom: 0;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pt-1\.5 {
  padding-top: 0.375rem;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pr-5 {
  padding-right: 1.25rem;
}
.pl-6 {
  padding-left: 1.5rem;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.align-middle {
  vertical-align: middle;
}
.font-mono {
  font-family: Sailec, Roboto, Helvetica Neue, Arial, sans-serif;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-xxs {
  font-size: 0.625rem;
  line-height: 0.625rem;
}
.font-medium {
  font-weight: 500;
}
.font-normal {
  font-weight: 400;
}
.font-bold {
  font-weight: 700;
}
.font-light {
  font-weight: 300;
}
.font-semibold {
  font-weight: 600;
}
.uppercase {
  text-transform: uppercase;
}
.leading-8 {
  line-height: 2rem;
}
.leading-7 {
  line-height: 1.75rem;
}
.leading-relaxed {
  line-height: 1.625;
}
.leading-normal {
  line-height: 1.5;
}
.leading-snug {
  line-height: 1.375;
}
.leading-4 {
  line-height: 1rem;
}
.leading-5 {
  line-height: 1.25rem;
}
.leading-3 {
  line-height: 0.75rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.leading-tight {
  line-height: 1.25;
}
.tracking-wide {
  letter-spacing: 0.025em;
}
.tracking-1 {
  letter-spacing: 1px;
}
.tracking-widest {
  letter-spacing: 0.1em;
}
.text-turquoise-800 {
  --tw-text-opacity: 1;
  color: rgba(25, 179, 138, var(--tw-text-opacity));
}
.text-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(78, 19, 195, var(--tw-text-opacity));
}
.text-lemon-800 {
  --tw-text-opacity: 1;
  color: rgba(204, 158, 0, var(--tw-text-opacity));
}
.text-success-500 {
  --tw-text-opacity: 1;
  color: rgba(46, 204, 113, var(--tw-text-opacity));
}
.text-info-700 {
  --tw-text-opacity: 1;
  color: rgba(1, 113, 244, var(--tw-text-opacity));
}
.text-warning-500 {
  --tw-text-opacity: 1;
  color: rgba(255, 181, 59, var(--tw-text-opacity));
}
.text-error-500 {
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity));
}
.text-mediumgrey {
  --tw-text-opacity: 1;
  color: rgba(82, 91, 101, var(--tw-text-opacity));
}
.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(5, 30, 209, var(--tw-text-opacity));
}
.text-turquoise-500 {
  --tw-text-opacity: 1;
  color: rgba(31, 222, 171, var(--tw-text-opacity));
}
.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(110, 47, 235, var(--tw-text-opacity));
}
.text-lemon-500 {
  --tw-text-opacity: 1;
  color: rgba(255, 198, 0, var(--tw-text-opacity));
}
.text-info-500 {
  --tw-text-opacity: 1;
  color: rgba(69, 154, 254, var(--tw-text-opacity));
}
.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(47, 72, 250, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-offwhite {
  --tw-text-opacity: 1;
  color: rgba(236, 238, 241, var(--tw-text-opacity));
}
.text-brand-500 {
  --tw-text-opacity: 1;
  color: rgba(0, 176, 255, var(--tw-text-opacity));
}
.text-lightgrey {
  --tw-text-opacity: 1;
  color: rgba(120, 132, 148, var(--tw-text-opacity));
}
.text-darkgrey {
  --tw-text-opacity: 1;
  color: rgba(45, 51, 58, var(--tw-text-opacity));
}
.text-error {
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity));
}
.text-error-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 232, 231, var(--tw-text-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(159, 166, 178, var(--tw-text-opacity));
}
.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(22, 30, 46, var(--tw-text-opacity));
}
.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.text-brand-800 {
  --tw-text-opacity: 1;
  color: rgba(0, 141, 204, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-lightergrey {
  --tw-text-opacity: 1;
  color: rgba(250, 250, 250, var(--tw-text-opacity));
}
.text-success-700 {
  --tw-text-opacity: 1;
  color: rgba(36, 158, 88, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(37, 47, 63, var(--tw-text-opacity));
}
.text-grey-700 {
  --tw-text-opacity: 1;
  color: rgba(208, 212, 217, var(--tw-text-opacity));
}
.text-carbon-100 {
  --tw-text-opacity: 1;
  color: rgba(184, 197, 210, var(--tw-text-opacity));
}
.text-success-800 {
  --tw-text-opacity: 1;
  color: rgba(26, 116, 65, var(--tw-text-opacity));
}
.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(210, 214, 220, var(--tw-text-opacity));
}
.text-warning-800 {
  --tw-text-opacity: 1;
  color: rgba(128, 79, 0, var(--tw-text-opacity));
}
.text-turquoise-200 {
  --tw-text-opacity: 1;
  color: rgba(121, 236, 205, var(--tw-text-opacity));
}
.text-lemon-200 {
  --tw-text-opacity: 1;
  color: rgba(255, 221, 102, var(--tw-text-opacity));
}
.text-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(206, 185, 248, var(--tw-text-opacity));
}
.text-transparent {
  color: transparent;
}
.opacity-25 {
  opacity: 0.25;
}
.opacity-75 {
  opacity: 0.75;
}
.opacity-100 {
  opacity: 1;
}
.opacity-0 {
  opacity: 0;
}
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.shadow,
.shadow-sm {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.shadow-none {
  --tw-shadow: 0 0 #0000;
}
.shadow-lg,
.shadow-none {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}
.shadow-inner,
.shadow-md {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.shadow-outline {
  --tw-shadow: 0 0 0 3px rgba(156, 163, 175, 0.5);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
    var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
}
.ring,
.ring-1 {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
    var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
}
.ring-info-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(69, 154, 254, var(--tw-ring-opacity));
}
.ring-brand-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity));
}
.ring-error-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity));
}
.ring-opacity-50 {
  --tw-ring-opacity: 0.5;
}
.transition-transform {
  transition-duration: 0.15s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.transition {
  transition-duration: 0.15s;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
    transform, filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.transition-opacity {
  transition-duration: 0.15s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.transition-colors {
  transition-duration: 0.15s;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.transition-all {
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.duration-200 {
  transition-duration: 0.2s;
}
.duration-300 {
  transition-duration: 0.3s;
}
.duration-100 {
  transition-duration: 0.1s;
}
.duration-150 {
  transition-duration: 0.15s;
}
.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-linear {
  transition-timing-function: linear;
}
.line-clamp-2 {
  -webkit-line-clamp: 2;
}
.line-clamp-2,
.line-clamp-3 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
.line-clamp-3 {
  -webkit-line-clamp: 3;
}
.line-clamp-4 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  overflow: hidden;
}
.first\:mt-0:first-child {
  margin-top: 0;
}
.last\:border-transparent:last-child {
  border-color: transparent;
}
.focus-within\:border-info-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(169, 208, 255, var(--tw-border-opacity));
}
.focus-within\:ring-1:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
    var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus-within\:ring-brand-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(102, 208, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-brand-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 176, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-opacity-50:focus-within {
  --tw-ring-opacity: 0.5;
}
.hover\:bg-brand-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 141, 204, var(--tw-bg-opacity));
}
.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 245, 247, var(--tw-bg-opacity));
}
.hover\:bg-brand-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 176, 255, var(--tw-bg-opacity));
}
.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.hover\:bg-carbon-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(18, 63, 104, var(--tw-bg-opacity));
}
.hover\:bg-opacity-25:hover {
  --tw-bg-opacity: 0.25;
}
.hover\:bg-opacity-100:hover {
  --tw-bg-opacity: 1;
}
.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.focus\:z-10:focus {
  z-index: 10;
}
.focus\:border-gray-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(210, 214, 220, var(--tw-border-opacity));
}
.focus\:border-info-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(169, 208, 255, var(--tw-border-opacity));
}
.focus\:bg-brand-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 158, 230, var(--tw-bg-opacity));
}
.focus\:text-turquoise-700:focus {
  --tw-text-opacity: 1;
  color: rgba(28, 201, 155, var(--tw-text-opacity));
}
.focus\:text-purple-700:focus {
  --tw-text-opacity: 1;
  color: rgba(89, 22, 223, var(--tw-text-opacity));
}
.focus\:text-lemon-700:focus {
  --tw-text-opacity: 1;
  color: rgba(229, 178, 0, var(--tw-text-opacity));
}
.focus\:text-success-700:focus {
  --tw-text-opacity: 1;
  color: rgba(36, 158, 88, var(--tw-text-opacity));
}
.focus\:text-info-700:focus {
  --tw-text-opacity: 1;
  color: rgba(1, 113, 244, var(--tw-text-opacity));
}
.focus\:text-warning-700:focus {
  --tw-text-opacity: 1;
  color: rgba(204, 127, 0, var(--tw-text-opacity));
}
.focus\:text-error-700:focus {
  --tw-text-opacity: 1;
  color: rgba(213, 25, 12, var(--tw-text-opacity));
}
.focus\:text-blue-800:focus {
  --tw-text-opacity: 1;
  color: rgba(5, 30, 209, var(--tw-text-opacity));
}
.focus\:text-darkgrey:focus {
  --tw-text-opacity: 1;
  color: rgba(45, 51, 58, var(--tw-text-opacity));
}
.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000;
}
.focus\:shadow-none:focus,
.focus\:shadow-outline-brand:focus {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.focus\:shadow-outline-brand:focus {
  --tw-shadow: 0 0 0 2px #66d0ff;
}
.focus\:shadow-outline-gray:focus {
  --tw-shadow: 0 0 0 3px hsla(0, 96%, 89%, 0.5);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
    var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width))
    var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus-visible\:shadow-outline-gray:focus-visible {
  --tw-shadow: 0 0 0 3px hsla(0, 96%, 89%, 0.5);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.focus-visible\:ring:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
    var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus-visible\:ring-1:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
    var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus-visible\:ring-teal-400:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(22, 189, 202, var(--tw-ring-opacity));
}
.focus-visible\:ring-indigo-400:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(141, 162, 251, var(--tw-ring-opacity));
}
.focus-visible\:ring-orange-400:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 138, 76, var(--tw-ring-opacity));
}
.focus-visible\:ring-green-400:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 196, 141, var(--tw-ring-opacity));
}
.focus-visible\:ring-gray-400:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(159, 166, 178, var(--tw-ring-opacity));
}
.focus-visible\:ring-gray-200:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}
.focus-visible\:ring-error-500:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 67, 54, var(--tw-ring-opacity));
}
.focus-visible\:ring-warning-500:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 181, 59, var(--tw-ring-opacity));
}
.focus-visible\:ring-success-500:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(46, 204, 113, var(--tw-ring-opacity));
}
.focus-visible\:ring-info-500:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(69, 154, 254, var(--tw-ring-opacity));
}
.focus-visible\:ring-brand-200:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(102, 208, 255, var(--tw-ring-opacity));
}
.focus-visible\:ring-opacity-50:focus-visible {
  --tw-ring-opacity: 0.5;
}
.active\:translate-y-px:active {
  --tw-translate-y: 1px;
  transform: var(--tw-transform);
}
.active\:translate-y-0:active {
  --tw-translate-y: 0px;
  transform: var(--tw-transform);
}
.active\:bg-carbon-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(11, 38, 62, var(--tw-bg-opacity));
}
.active\:bg-gray-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 245, 247, var(--tw-bg-opacity));
}
.active\:bg-gray-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(210, 214, 220, var(--tw-bg-opacity));
}
.active\:bg-opacity-50:active {
  --tw-bg-opacity: 0.5;
}
.active\:shadow-outline:active {
  --tw-shadow: 0 0 0 3px rgba(156, 163, 175, 0.5);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.active\:outline-none:active {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.disabled\:text-gray-300:disabled {
  --tw-text-opacity: 1;
  color: rgba(210, 214, 220, var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .sm\:left-auto {
    left: auto;
  }
  .sm\:mt-0 {
    margin-top: 0;
  }
  .sm\:mb-0 {
    margin-bottom: 0;
  }
  .sm\:mr-3 {
    margin-right: 0.75rem;
  }
  .sm\:block {
    display: block;
  }
  .sm\:inline {
    display: inline;
  }
  .sm\:flex {
    display: flex;
  }
  .sm\:table-cell {
    display: table-cell;
  }
  .sm\:table-row {
    display: table-row;
  }
  .sm\:grid {
    display: grid;
  }
  .sm\:hidden {
    display: none;
  }
  .sm\:w-96 {
    width: 24rem;
  }
  .sm\:w-auto {
    width: auto;
  }
  .sm\:w-28 {
    width: 7rem;
  }
  .sm\:w-0 {
    width: 0;
  }
  .sm\:flex-1 {
    flex: 1 1 0%;
  }
  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: var(--tw-transform);
  }
  .sm\:translate-x-2 {
    --tw-translate-x: 0.5rem;
    transform: var(--tw-transform);
  }
  .sm\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: var(--tw-transform);
  }
  .sm\:flex-row {
    flex-direction: row;
  }
  .sm\:flex-wrap {
    flex-wrap: wrap;
  }
  .sm\:items-end {
    align-items: flex-end;
  }
  .sm\:items-center {
    align-items: center;
  }
  .sm\:justify-between {
    justify-content: space-between;
  }
  .sm\:gap-2 {
    gap: 0.5rem;
  }
  .sm\:gap-5 {
    gap: 1.25rem;
  }
  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  }
  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
  }
  .sm\:rounded-lg {
    border-radius: 0.5rem;
  }
  .sm\:border-none {
    border-style: none;
  }
  .sm\:p-6 {
    padding: 1.5rem;
  }
  .sm\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .sm\:py-2 {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
  .sm\:pl-7 {
    padding-left: 1.75rem;
  }
  .sm\:pl-16 {
    padding-left: 4rem;
  }
  .sm\:pl-28 {
    padding-left: 7rem;
  }
  .sm\:pl-40 {
    padding-left: 10rem;
  }
  .sm\:pl-52 {
    padding-left: 13rem;
  }
  .sm\:pl-64 {
    padding-left: 16rem;
  }
  .sm\:pl-72 {
    padding-left: 18rem;
  }
  .sm\:pr-3 {
    padding-right: 0.75rem;
  }
  .sm\:pt-2\.5 {
    padding-top: 0.625rem;
  }
  .sm\:pt-2 {
    padding-top: 0.5rem;
  }
  .sm\:pr-7 {
    padding-right: 1.75rem;
  }
  .sm\:pr-2 {
    padding-right: 0.5rem;
  }
  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .sm\:leading-none {
    line-height: 1;
  }
  .sm\:leading-5 {
    line-height: 1.25rem;
  }
  .sm\:first\:pl-7:first-child {
    padding-left: 1.75rem;
  }
  .sm\:last\:pr-7:last-child {
    padding-right: 1.75rem;
  }
}
@media (min-width: 768px) {
  .md\:relative {
    position: relative;
  }
  .md\:flex {
    display: flex;
  }
  .md\:hidden {
    display: none;
  }
  .md\:w-24 {
    width: 6rem;
  }
  .md\:w-72 {
    width: 18rem;
  }
  .md\:w-80 {
    width: 20rem;
  }
  .md\:flex-none {
    flex: none;
  }
  .md\:flex-1 {
    flex: 1 1 0%;
  }
  .md\:flex-shrink-0 {
    flex-shrink: 0;
  }
  .md\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  }
  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
  }
  .md\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-left: calc(0.75rem * (1 - var(--tw-space-x-reverse)));
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  }
  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .md\:pt-5 {
    padding-top: 1.25rem;
  }
  .md\:pl-6 {
    padding-left: 1.5rem;
  }
  .md\:text-left {
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .lg\:block {
    display: block;
  }
  .lg\:hidden {
    display: none;
  }
  .lg\:w-36 {
    width: 9rem;
  }
  .lg\:w-96 {
    width: 24rem;
  }
  .lg\:flex-3 {
    flex: 3 3 0%;
  }
  .lg\:gap-x-10 {
    -moz-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }
  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .lg\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
}
.image-grid {
  grid-template-columns: repeat(auto-fill, minmax(176px, 1fr));
}

.bg-green-400-75 {
  background-color: #78cf91;
}

.bg-green-400 {
  background-color: rgba(88, 197, 119, 0.9419);
}

.bg-main {
  background-color: #e5e5e5;
}
.bg-sidebar-active {
  background-color: rgba(178, 255, 199, 0.4) !important;
}

.text-primary {
  color: #2ca64f;
}

.login__form-container {
  width: 30rem;
  border-radius: 8px;
  padding: 2.5rem;
  right: 10px;
  top: 285px;
  position: absolute;
}
.login__form-logo {
  width: 15.5rem;
  margin-bottom: 0.75rem;
}

.dial-pad {
  display: flex;
  flex-flow: row wrap;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 15px;
}

.dial-call {
  width: 320px;
  height: 510px;
  border-radius: 0.5rem;
  border-top-left-radius: 19px;
}

.dial-calling {
  height: 326px;
  width: 320px;
  border-radius: 0.5rem;
  background: linear-gradient(rgb(191, 237, 237) 0%, rgb(255, 255, 255) 50%);
}

.dial-pad-number {
  width: 29%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  font-size: 25px;
  font-weight: 200;
  cursor: pointer;
  margin: 0 6px;
}

.dial-pad-number-circle {
  background-color: #e4e4e4;
  border-radius: 100px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  transition: all 200ms;
}

.dial-pad-number-circle:hover {
  background-color: #f4f4f4;
}

.dial-pad-number-circle:active {
  background-color: #c2c2c2;
}

.no-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.dial-mic {
  background-color: #e4e4e4;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  transition: all 200ms;
}

.call-user {
  height: 400px;
  width: 95%;
  border-radius: 0.5rem;
  background: linear-gradient(rgb(157, 221, 171) 0%, rgb(253, 253, 253) 50%);
}

.call-history {
  height: 257px;
}

.bg-avatar {
  height: 140px;
  width: 140px;
}

.bare-button-table {
  border-radius: 0px !important;
  border-width: 0px !important;
  height: 1rem !important;
  padding: 0 !important;
  color: #008dcc !important;
}

.demo-view {
  width: 200px;
  height: 200px;
}

.center-block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .demo-view-give {
  width: 400px;
  height: 400px;
}

.popup-content {
  background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
} */
